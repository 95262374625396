import React from "react";
import Layout from "../components/layout";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import ReactTooltip from "react-tooltip";

import LinkedIn from "../images/icon/linkedin.svg";
import Instagram from "../images/icon/instagram.svg";
import Email from "../images/icon/email.svg";
import PDGA from "../images/icon/pdga.svg";

import "../styles/w3.css";

const IndexPage = () => (
	<Layout title="Home">
		<div className={"centered w3-margin-top index w3-padding-large"}>
			<h1 className={"w3-jumbo w3-animate-top w3-center"}>ALEX MAIN</h1>
			<hr
				className={"w3-border-white"}
				style={{
					margin: `auto`,
					width: `60%`,
					borderWidth: 2,
					position: `relative`,
				}}
			/>
			<div className={"w3-row w3-section"}>
				<OutboundLink
					href="https://www.linkedin.com/in/alexandermain/"
					target="_blank"
					rel="noopener noreferrer"
					className={"w3-center w3-col"}
					style={{ width: `25%` }}
					data-tip="LinkedIn"
				>
					<img src={LinkedIn} alt="LinkedIn Icon" className={"icon-bar"} />
				</OutboundLink>

				<OutboundLink
					href="https://www.instagram.com/alexma.in/"
					target="_blank"
					rel="noopener noreferrer"
					className={"w3-center w3-col"}
					style={{ width: `25%` }}
					data-tip="Instagram"
				>
					<img src={Instagram} alt="Instagram Icon" className={"icon-bar"} />
				</OutboundLink>

				<OutboundLink
					href="mailto:mail@alexma.in"
					target="_blank"
					rel="noopener noreferrer"
					className={"w3-center w3-col"}
					style={{ width: `25%` }}
					data-tip="Email Me"
				>
					<img src={Email} alt="Email Icon" className={"icon-bar"} />
				</OutboundLink>

				<OutboundLink
					href="https://www.pdga.com/player/101190/"
					target="_blank"
					rel="noopener noreferrer"
					className={"w3-center w3-col"}
					style={{ width: `25%` }}
					data-tip="PDGA"
				>
					<img src={PDGA} alt="Disc Golf Icon" className={"icon-bar"} />
				</OutboundLink>

				<ReactTooltip place="bottom" type="info" effect="solid" />
			</div>
		</div>
	</Layout>
);

export default IndexPage;
